:root {
  --primaryColor: #faf9f8;
  --primaryWhite: #ffffff;
  --secColor: #256ab8;
  --secColor2: #1f78c7;
  --secColor3: #4990d1;
  --terColor: #ec4288;
  --accColor: #54738F;
  --accColor2: #6D8DA9;
  --appBg: #faf9f8;
}

html {
  background-color: var(--appBg) !important;
}

body {
  font-family: 'Nunito Sans', sans-serif !important;
  color: var(--secColor) !important;
}

.fullPage {
  margin-top: 2%;
  min-height: 80vh;
  min-width: 100vw;
}

.App {
  background-color: var(--appBg) !important;
  font-family: 'Nunito Sans', sans-serif !important;
  color: var(--secColor) !important;
  min-height: 100vh;
}

a {
  color: var(--appBg) !important;
}

.a2 {
  color: var(--secColor) !important;
}

.MuiTypography-root {
  font-family: 'Nunito Sans', sans-serif !important;
}

.Mui-selected {
  background: var(--secColor) !important;
  color: var(--appBg) !important;
}

.react-file-reader-button {
  width: 90vw;
}